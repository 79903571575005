<template>
    <div>
        <adminUserList ></adminUserList>
    </div>
</template>
<script>

import adminUserList from '@/components/admin/user/list.vue';

export default {
    components: {
        adminUserList
    },
    data () {
        let self = (window.x = this)
        return {
        }
    },
    methods: {
        demo () {
        },
        // end 
    },
    mounted () {
        // this.getData()
    },
    watch: {},
    computed: {}
}
</script>
