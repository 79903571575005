<template>
    <div>
        <Drawer
            v-model="drawer_edit"
            width="500"
            :mask-closable="true"
            :closable="false"
        >
            <userEdit :_id="edit_id" @closePage="closeEdit"></userEdit>
        </Drawer>
        <fitTable
            :columns="columns" 
            :data="data" 
            :loading="loading" 
            :header_height=100
        >
        </fitTable>
        <Row>
            <Button v-if="load_more_show" type="primary" :disabled="load_more_disabled" @click="getData" long>加载更多</Button>
        </Row>
        <!-- <Page
            :total="total_page"
            :current="current_page"
            :page-size="page_size"
            @on-change="onPageChange"
            show-total
            show-elevator
        ></Page> -->
    </div>
</template>
<script>

import { getAdminUsers, delAdminUser } from '@/api/admin';
import fitTable from '../../basic/table.vue';
import adminUserGroupSearch from '../user_group/search.vue';
import userEdit from './edit.vue';

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
            props: {
                confirm: true,
                title: '您确定要删除这个账号吗?',
                transfer: true
            },
            on: {
                'on-ok': () => {
                    vm.delete(params.row.id, params.index);
                }
            }
        },
        [
            h(
                'Button',
                {
                    style: {
                        margin: '2px 2px'
                    },
                    props: {
                        type: 'warning',
                        size: 'small'
                    }
                },
                '删除'
            )
        ]
    )
}

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'info',
            },
            on: {
                click: () => {
                    // 路由跳转到编辑页面
                    // let query = { sport_id: vm.sport_id, player_id: params.row.id };
                    // let resolve_data = vm.$router.resolve({ path: '/ballsports/tennis/player/detail', query: query });
                    // window.open(resolve_data.href, '_blank');
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "admin-user-list",
    props: {
    },
    components: {
        fitTable,
        adminUserGroupSearch,
        userEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            cursor: 0,
            loading: true,
            load_more_disabled: false,
            load_more_show: true,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    key: 'id',
                    width: 300,
                },
                {
                    title: '名称',
                    align: 'center',
                    key: 'name',
                    minWidth: 80,
                },
                {
                    title: '头像',
                    align: 'center',
                    key: 'avatar',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        return  h("Row", { attrs: {justify: 'center' }}, [h('Avatar', { attrs: { src: currentRow.avatar , size:"large" }, })],)
                    }
                },
                {
                    title: '用户组',
                    align: 'center',
                    key: 'group',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let group = currentRow.group;
                        return  [
                            h('span', {class: 'text-link', on: {
                                'click':() => {
                                    // self.editGroup(group.id);
                                }
                            } },  group.id + ' ',  ),
                            h('span', {class: 'text-main',on: {
                                'click':() => {
                                    // self.editGroup(group.id);
                                }
                            } }, group.name,  )
                        ]
                        // return [
                        //     h(adminUserGroupSearch, {
                        //         props: {
                        //             'v-model': params.row.group,
                        //         }
                        //     })
                        // ]
                    }
                },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 100,
                    render (h, params) {
                        return [ editButton(self, h, params), delButton(self, h, params) ]
                    }
                }
            ],
            data: [],
            edit_id: '',
            drawer_edit: false,
        }
    },
    methods: {
        // end
        getData () {
            let self = this;
            this.loading = true;
            // 搜索日期
            var params = {
                cursor: self.cursor,
            }
            getAdminUsers(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    // this.data = response.data.data.list;
                    if (response.data.data.list.length > 0){
                        response.data.data.list.forEach(element => {
                            this.data.push(element);
                        })
                    }
                    this.cursor = response.data.data.cursor;
                    if (response.data.data.cursor == 0){
                        self.load_more_disabled = true;
                        self.load_more_show = false;
                    }
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        delete (_id, _index) {
            let self = this;
            // 搜索日期
            var params = {
                'id': _id,
            }
            delAdminUser(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    self.data.splice( _index, 1);
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end
        editGroup(){

        },
        ok(){

        },
        // end
        closeEdit(data){
            let self = this;
            self.drawer_edit = false;
            if (data){
                // 更新或者新增数据
                if (data.unionid){
                    var update_status = false;
                    self.data.forEach(element => {
                        if (element.id === data.unionid){
                            // element.name = data.name;
                            element.group = data.group;
                            update_status = true;
                        }
                    })
                    // 为找到固定id 新增一条
                    if (!update_status){
                        // self.data.unshift(data);
                    }
                }else{
                    // 新增
                    // self.data.unshift(data);
                }
            }
        },
        // end
    },
    mounted () {
        this.getData();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    /* @import "list.css" */
</style>