<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Form label-position="left" :label-width="80" >
            <FormItem label="id: " v-if="data.unionid">
                <Input v-model="data.unionid"  disabled></Input>
            </FormItem>
            <!-- <FormItem label="名称: " >
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name" ></Input>
                    </Col>
                </Row>
            </FormItem> -->
            <FormItem label="用户组:" >
                <adminUserGroupSearch v-model="data.group"></adminUserGroupSearch>
            </FormItem>
        </Form>
        <Row :gutter="16" align="middle">
            <Col span="12" style="text-align: left" >
                <Button type="default"  @click="cancle" long size="default">取消</Button>
            </Col>
            <Col span="12" style="text-align: right">
                <Button type="primary"  @click="save" long size="default">保存</Button>
            </Col>
        </Row>
    </div>
</template>

<script>

import { getAdminUser, updateAdminUser } from '@/api/admin';
import adminUserGroupSearch from '../user_group/search.vue';

export default {
    name: "user-edit",
    props: {
        _id: String,   // 用户id
    },
    components: {
        adminUserGroupSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            spinShow: false,
            data: {
                group: {id:0, name:''},
            },
        }
    },
    methods: {
        // 获取比赛的基础数据
        getData () {
            let self = this;
            this.spinShow = true;
            // 搜索日期
            if (self._id ){
                var params = {
                    'id': self._id,
                }
                getAdminUser( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        self.data = response.data.data;
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self.data = {
                    group: {id:0, name:''},
                };
            }
            self.spinShow = false;
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        cancle(){
            let self = this;
            self.emit(false);
        },
        save() {
            let self = this;
            let params = {
                id: self.data.unionid,
                groupid: self.data.group.id,
            };
            // console.log(params);
            updateAdminUser(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit(self.data);
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
    },
    watch: {
        '_id': {
            handler(value){
                let self = this;
                if (value ){
                    // console.log("Detail Get Data _id", value);
                    self.getData();
                    // console.log(self.data);
                };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>