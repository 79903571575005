import http from '@/utils/http';

export const getAdminUser = (params) => {
    return http.request({
        url: "/v1/admin/user/get",
        method: 'get',
        params: params,
    })
}
export const refreshAdminUser = (params) => {
    return http.request({
        url: "/v1/admin/user/refresh",
        method: 'get',
        params: params,
    })
}
// 获取用户列表
export const getAdminUsers = (params) => {
    return http.request({
        url: "/v1/admin/user/list",
        method: 'get',
        params: params,
    })
}
// 删除用户
export const delAdminUser = (params) => {
    return http.request({
        url: "/v1/admin/user/delete",
        method: 'post',
        data: params,
    })
}
// 更新用户
export const updateAdminUser = (params) => {
    return http.request({
        url: "/v1/admin/user/update",
        method: 'post',
        data: params,
    })
}

// 获取单个组的配置
export const getAdminUserGroup = (params) => {
    return http.request({
        url: "/v1/admin/user_group/get",
        method: 'get',
        params: params,
    })
}
// 获取用户组列表
export const getAdminUserGroups = (params) => {
    return http.request({
        url: "/v1/admin/user_group/list",
        method: 'get',
        params: params,
    })
}

// 编辑用户组
export const updateAdminUserGroup = (params) => {
    return http.request({
        url: "/v1/admin/user_group/update",
        method: 'post',
        data: params,
    })
}

// 删除用户组
export const delAdminUserGroup = (params) => {
    return http.request({
        url: "/v1/admin/user_group/delete",
        method: 'post',
        data: params,
    })
}

// 搜索用户组
export const searchAdminUserGroup = (params) => {
    return http.request({
        url: "/v1/admin/user_group/search",
        method: 'post',
        data: params,
    })
}
